<template>
  <div class="actions" v-if="me">
    <div class="actions__mobile">
      <SelectComponent
        title="Тип заявок"
        labelName="title"
        v-model="type"
        :clearable="false"
        :options="buttons"
        @input="setActiveType($event)"
      />
      <button type="button" class="btn-blue btn-blue--outline btn-blue--icon-left">
        <FilterIcon />
        <span>Фильтры</span>
      </button>
      <button class="btn" v-if="isEmployeeOrAdmin" type="button" @click="scanQR">Отсканировать QR код</button>
    </div>
    <div class="actions__buttons" v-if="type">
      <button
        v-for="(b, i) in buttons"
        :key="i"
        class="pill"
        :class="{ 'pill--selected': b.id === type.id }"
        @click="setActiveType(b)"
        type="button"
      >
        {{ b.title }}
      </button>
    </div>
    <div class="actions__filters">
      <div class="actions__filters-list">
        <FilterSelectComponent
          title=" Поставщик"
          labelName="title"
          :loading="dataLoading"
          v-model="form.providers.value"
          @input="
            $emit('change', {
              name: 'providers',
              value: form.providers.value,
            })
          "
          :options="providers"
          multiple
        />
        <FilterSelectComponent
          title="Подрядчик"
          labelName="title"
          :loading="dataLoading"
          v-model="form.contractors.value"
          @input="
            $emit('change', {
              name: 'contractors',
              value: form.contractors.value,
            })
          "
          :options="contractors"
          multiple
        />
        <DateComponent
          range
          title="Дата"
          :loading="dataLoading"
          v-model="form.date.value"
          @input="
            $emit('change', {
              name: 'date',
              value: form.date.value,
            })
          "
        />
        <DateComponent
          range
          title="Дата въезда"
          :loading="dataLoading"
          v-model="form.date_entered.value"
          @input="
            $emit('change', {
              name: 'date_entered',
              value: form.date_entered.value,
            })
          "
        />
        <FilterSelectComponent
          title="Куратор"
          labelName="title"
          :loading="dataLoading"
          v-model="form.suppliers.value"
          @input="
            $emit('change', {
              name: 'suppliers',
              value: form.suppliers.value,
            })
          "
          :options="suppliers"
          multiple
        />
        <FilterSelectComponent
          title="Статус"
          :loading="dataLoading"
          v-model="form.status.value"
          @input="
            $emit('change', {
              name: 'status',
              value: form.status.value,
            })
          "
          :options="statuses"
          multiple
        />
        <button class="btn btn--small" type="button" @click="reset">Сбросить</button>
      </div>
    </div>
  </div>
</template>

<script>
import FilterSelectComponent from "@/components/inputs/FilterSelectComponent";
import DateComponent from "@/components/inputs/DateComponent";
import SelectComponent from "@/components/inputs/SelectComponent";
import FilterIcon from "@/components/svg/FilterIcon";

export default {
  name: "RequestsListActions",
  data() {
    return {
      dataLoading: true,
      type: null,
      form: {
        providers: {
          value: null,
          message: null,
        },
        suppliers: {
          value: null,
          message: null,
        },
        contractors: {
          value: null,
          message: null,
        },
        date: {
          value: null,
          message: null,
        },
        date_entered: {
          value: null,
          message: null,
        },
        status: {
          value: null,
          message: null,
        },
      },
    };
  },
  computed: {
    defaultType() {
      return {
        id: 2,
        title: "Пропуска",
        route: "Passes",
        show: true,
      };
    },
    buttons() {
      return [
        {
          id: null,
          title: "Все заявки",
          route: "Requests",
          show: true,
        },
        {
          id: 1,
          title: "Назначенные мне",
          route: "Requests",
          show: this.isSupplier,
        },
        this.defaultType,
      ].filter((b) => b.show);
    },
    isSupplier() {
      if (this.me && this.me.role && this.me.role.length) {
        return !!this.me.role.filter((r) => {
          return r.name === "supplier";
        }).length;
      }
      return false;
    },
    isProvider() {
      if (this.me && this.me.role && this.me.role.length) {
        return !!this.me.role.filter((r) => {
          return r.name === "provider";
        }).length;
      }
      return false;
    },
    isContractor() {
      if (this.me && this.me.role && this.me.role.length) {
        return !!this.me.role.filter((r) => {
          return r.name === "contractor";
        }).length;
      }
      return false;
    },
    isEmployeeOrAdmin() {
      if (this.me && this.me.role && this.me.role.length) {
        return !!this.me.role.filter((r) => {
          return r.name === "employee" || r.name === "admin";
        }).length;
      }
      return false;
    },
    me() {
      return this.$store.state.me;
    },
    statuses() {
      return this.$store.state.passes_statuses;
    },
    providers() {
      return this.$store.state.users
        .filter((u) => {
          return u.role.filter((r) => {
            return r.name === "provider";
          }).length;
        })
        .map((u) => {
          if (u.company && u.company.company_name) {
            u.title = u.company.company_name;
          } else {
            u.title = `${u.surname} ${u.name} ${u.patronymic}`;
          }
          return u;
        });
    },
    suppliers() {
      return this.$store.state.users
        .filter((u) => {
          return u.role.filter((r) => {
            return r.name === "supplier";
          }).length;
        })
        .map((u) => {
          u.title = `${u.surname} ${u.name} ${u.patronymic}`;
          return u;
        });
    },
    contractors() {
      return this.$store.state.users
        .filter((u) => {
          return u.role.filter((r) => {
            return r.name === "contractor";
          }).length;
        })
        .map((u) => {
          if (u && u.company) {
            u.id = u.company.id;
            u.title = u.company.company_name;
            return u;
          }
          return (u.title = null);
        })
        .filter((u) => u && u.title);
    },
  },
  created() {
    this.dataLoading = true;
    this.type = this.defaultType;
    this.$store
      .dispatch("GET_PASSES_LIST_ACTIONS_DATA", {
        context: this,
      })
      .then(() => {
        let storageVariables = localStorage.getItem("passes_list." + this.me.id);
        if (storageVariables) {
          storageVariables = JSON.parse(storageVariables);
          if (storageVariables.providers) {
            this.form.providers.value = this.providers.filter((p) =>
              storageVariables.providers.includes(p.id)
            );
          }
          if (storageVariables.suppliers) {
            this.form.suppliers.value = this.suppliers.filter((s) =>
              storageVariables.suppliers.includes(s.id)
            );
          }
          if (storageVariables.contractors) {
            this.form.contractors.value = this.contractors.filter((c) =>
              storageVariables.contractors.includes(c.id)
            );
          }
          if (storageVariables.start_date && storageVariables.end_date) {
            this.form.date.value = {
              end: storageVariables.end_date,
              start: storageVariables.start_date,
            };
          }
          if (storageVariables.entered_start_date && storageVariables.entered_end_date) {
            this.form.date_entered.value = {
              end: storageVariables.entered_end_date,
              start: storageVariables.entered_start_date,
            };
          }
          if (storageVariables.status) {
            this.form.status.value = this.statuses.filter((c) => storageVariables.status.includes(c.id));
          }
        }
        this.dataLoading = false;
      });
  },
  methods: {
    setActiveType(event) {
      if (!event) {
        this.type = this.defaultType;
        event = this.type;
      }
      // Если нажали на "Назначенные мне" меняю сохраненные фильтр для списка заявок
      if (event.id === 1) {
        let storageVariables = localStorage.getItem("requests_list." + this.me.id);
        if (storageVariables) {
          storageVariables = JSON.parse(storageVariables);
          storageVariables.onlyMine = true;
          localStorage.setItem("requests_list." + this.me.id, JSON.stringify(storageVariables));
        }
      }
      if (event.route !== this.$route.name) {
        this.$router.push({ name: event.route });
      }
    },
    reset() {
      Object.keys(this.form).forEach((fK) => {
        if (fK !== "type") {
          this.form[fK].value = null;
        }
      });
      this.$emit("reset");
    },
    scanQR() {
      this.$emit("scannerChange");
    },
  },
  components: {
    FilterIcon,
    SelectComponent,
    DateComponent,
    FilterSelectComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/table-actions.styl"
</style>
