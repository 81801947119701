<template>
  <div :class="{ fullscreen: fullscreen }" ref="wrapper" @fullscreenchange="onFullscreenChange">
    <qrcode-stream @decode="onDecode" @init="onInit">
      <div class="qr__parent">
        <div class="qr__close-button" @click="closeScanner">
          <CloseQRIcon></CloseQRIcon>
        </div>
        <div
          class="qr__box"
          v-bind:class="{
            isAcceptBox: OneAccessByUuid && isAccept,
            isNotAcceptBox: OneAccessByUuid && !isAccept,
          }"
        >
          <div class="qr__loading" v-if="loading"></div>
          <p class="qr__error" v-if="error">{{ error }}</p>
        </div>
      </div>
      <div class="qr__actions">
        <div class="qr__message" v-if="OneAccessByUuid">{{ message }}</div>
        <router-link
          :to="getRoute(passId)"
          v-if="isAccept && passId"
          class="btn qr__agree-button"
          type="button"
        >
          Перейти к пропуску
        </router-link>
      </div>
    </qrcode-stream>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import CloseQRIcon from "./svg/CloseQRIcon";
export default {
  components: { QrcodeStream, CloseQRIcon },

  data() {
    return {
      result: "",
      error: "",
      fullscreen: true,
      OneAccessByUuid: null,
      loading: false,
      isAccept: false,
      passId: null,
    };
  },

  computed: {
    message() {
      return this.isAccept ? "Заявка найдена" : "Заявка не найдена";
    },
  },
  watch: {
    fullscreen(enterFullscreen) {
      if (enterFullscreen) {
        this.requestFullscreen();
      } else {
        this.exitFullscreen();
      }
    },
  },
  methods: {
    onFullscreenChange() {
      // This becomes important when the user doesn't use the button to exit
      // fullscreen but hits ESC on desktop, pushes a physical back button on
      // mobile etc.

      this.fullscreen = document.fullscreenElement !== null;
    },

    requestFullscreen() {
      const elem = this.$refs.wrapper;

      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
    },

    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen();
      }
    },
    async onDecode(result) {
      this.OneAccessByUuid = null;
      this.isAccept = false;
      /**
       * При декодированиии полученный от сканнера результат отправляем запросом
       * и полученный ответ выводим
       */
      this.loading = true;
      this.result = result;
      this.OneAccessByUuid = await this.$store.dispatch("GET_PASS_BY_UUID", {
        context: this,
        variables: {
          uuid: this.result,
        },
      });
      this.loading = false;

      if (this.OneAccessByUuid?.data?.OneAccessByUuid?.id) {
        this.passId = this.OneAccessByUuid.data.OneAccessByUuid.id;
        this.isAccept = true;
      }
    },

    async onInit(promise) {
      this.loading = true;
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "Ошибка: Необходимо дать доступ к камере";
        } else if (error.name === "NotFoundError") {
          this.error = "Ошибка: На этом устройстве отсутствует камера";
        } else if (error.name === "NotSupportedError") {
          this.error = "Ошибка: Сканнер QR-Кода не поддерживается на данном устройстве";
        } else if (error.name === "NotReadableError") {
          this.error = "Ошибка: Камера уже используется";
        } else if (error.name === "OverconstrainedError") {
          this.error = "Ошибка: Не подходящая камера на устройстве";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "Ошибка: Этот бразуер устарел";
        } else if (error.name === "InsecureContextError") {
          this.error = "Ошибка: Камерой можно пользоваться только на https";
        } else {
          this.error = `Ошибка: Камера работает неисправно`;
        }
      } finally {
        this.loading = false;
      }
    },

    closeScanner() {
      this.$emit("onClose");
    },

    getRoute(id) {
      return { name: "Pass", params: { id: id } };
    },
  },
};
</script>

<style lang="stylus">
@import '~@/styles/mixins/button';

.qr {
  &__error {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: var(--main_red);
    text-align: center;
    width: 320px;
    height: 320px;
    border-radius: 30px;
    background-color: var(--main_white);
  }

  &__fullscreen {
    position: fixed;
    z-index: 1000;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  &__parent {
    height: 50%;
    position: relative;
  }

  &__box {
    width: 320px;
    height: 320px;
    position: absolute;
    box-shadow: 0 0 0 100vmax rgb(0, 0, 0);
    border-radius: 30px;
    top: 50%;
    transform: translate(-50%, 0);
    left: 50%;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    margin-top: 150px;
    align-items: center;
    justify-content: center;
  }

  &__agree-button {
    width: 200px;
    margin-top: 41px;
    margin-left: auto;
    margin-right: auto;
    // z-index 2001;
  }

  &__message {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    color: white;
    z-index: 2001;
  }

  &__close-button {
    position: absolute;
    margin: 30px 30px 0 0;
    // position fixed;
    right: 0;
    top: 0;
    z-index: 2000;
  }

  &__loading {
    width: 320px;
    height: 320px;
    cursor: progress;
    background-color: var(--main_green_grass-l10);
    background-size: 40px 40px;
    background-image: linear-gradient(-45deg, rgba(#000, 0.25) 25%, #0000 25%, #0000 50%, rgba(#000, 0.25) 50%, rgba(#000, 0.25) 75%, #0000 75%, #0000);
    animation: loader 2s linear 0s infinite normal none running;
    border-radius: 30px;
  }
}

.decode-result {
  font-weight: bold;
  color: var(--main_green_grass);
  margin: auto;
}

.isAcceptBox {
  border: 10px solid var(--main_green_grass);
}

.isNotAcceptBox {
  border: 10px solid var(--main_red);
}
</style>
