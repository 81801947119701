<template>
  <main class="page">
    <ScannerQR v-if="isScannerQR" @onClose="showScanner"></ScannerQR>
    <h1 class="page__mobile-title">Пропуска</h1>
    <PassesListActions @change="actionsChange" @reset="actionsReset" @scannerChange="showScanner" />
    <Table
      :columns="columns"
      :rows="passes.data || []"
      listClass="request-list"
      :total="passes.paginatorInfo ? passes.paginatorInfo.total : 0"
      :page="page"
      pagination
      :initialSortBy="vgtFormattedOrdering"
      @paginate="changePage"
      @sort="changeSort"
    >
      <div slot="empty" class="td-container">
        <span class="table-span">
          <LoadingIndicator v-if="loading" />
          <template v-else>Данных нет</template>
        </span>
      </div>
      <template slot="table-row" slot-scope="{ row, column }">
        <router-link :to="getRoute(row.id)" class="td-container" v-if="column.field === 'id'">
          <span class="table-span">
            {{ row.id }}
          </span>
        </router-link>
        <router-link :to="getRoute(row.id)" class="td-container" v-if="column.field === 'created_at'">
          <span class="date">
            <span class="date__main">{{ row.created_at | formatDate }}</span>
            <span class="date__sub">{{ row.created_at | formatTime }}</span>
          </span>
        </router-link>
        <router-link :to="getRoute(row.id)" class="td-container" v-if="column.field === 'entered_at'">
          <span class="date" v-if="row.entered_at">
            <span class="date__main">{{ row.entered_at | formatDate }}</span>
            <span class="date__sub">{{ row.entered_at | formatTime }}</span>
          </span>
          <span v-else class="table-span">Не въехал</span>
        </router-link>
        <router-link :to="getRoute(row.id)" class="td-container" v-if="column.field === 'provider'">
          <span class="table-span">
            <template v-for="r in row.requests">
              {{ r.provider | getCompany }}
            </template>
          </span>
        </router-link>
        <router-link :to="getRoute(row.id)" class="td-container" v-if="column.field === 'supplier'">
          <span class="table-span">
            <template v-for="r in row.requests">
              {{ r.supplier | getFIO }}
            </template>
          </span>
        </router-link>
        <router-link :to="getRoute(row.id)" class="td-container" v-if="column.field === 'delivery'">
          <span class="table-span">
            <template v-for="r in row.requests">
              <template v-if="r.delivery && r.delivery.type && r.delivery.type.code === 2">
                {{ r.delivery | getCompany }}
              </template>
              <template v-else-if="r.delivery && r.delivery.type">
                Доставка осуществляется своими силами
              </template>
            </template>
          </span>
        </router-link>
        <router-link :to="getRoute(row.id)" class="td-container" v-if="column.field === 'status'">
          <span :class="getStatus(row)">
            {{ row.status.name }}
          </span>
        </router-link>
        <div class="td-container" v-if="column.field === 'actions'">
          <div class="table-actions">
            <router-link :to="getRoute(row.id)" class="table-action">
              <EyeOnIcon />
            </router-link>
          </div>
        </div>
      </template>
      <div slot="table-m-item" slot-scope="{ row }" class="card">
        <div class="card__header">
          <span class="card__id">{{ row.id }}</span>
          <span :class="getStatus(row)">{{ row.status.name }}</span>
        </div>
        <div class="card__body">
          <div class="card__item">
            <span class="card__item-title">Поставщик</span>
            <span class="card__item-value">
              <template v-for="r in row.requests">
                {{ r.provider | getCompany }}
              </template>
            </span>
          </div>
          <div class="card__item">
            <span class="card__item-title">Куратор</span>
            <span class="card__item-value">
              <template v-for="r in row.requests">
                {{ r.supplier | getFIO }}
              </template>
            </span>
          </div>
          <div class="card__item">
            <span class="card__item-title">Подрядчик</span>
            <span class="card__item-value">
              <template v-for="r in row.requests">
                <template v-if="r.delivery && r.delivery.type && r.delivery.type.code === 2">
                  {{ r.delivery | getCompany }}
                </template>
                <template v-else-if="r.delivery && r.delivery.type">
                  Доставка осуществляется своими силами
                </template>
              </template>
            </span>
          </div>
        </div>
        <div class="card__footer">
          <router-link
            :to="getRoute(row.id)"
            class="btn-gray btn-gray--outline btn-gray--md btn-gray--icon-left"
          >
            <EyeOnIcon />
            <span>Просмотр</span>
          </router-link>
        </div>
      </div>
    </Table>
  </main>
</template>

<script>
import PassesListActions from "./components/actions";
import Table from "@/components/Table";
import moment from "moment";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import EyeOnIcon from "@/components/svg/EyeOnIcon.vue";
import ScannerQR from "@/components/qrscanner";
export default {
  name: "PassesList",
  data() {
    return {
      isScannerQR: false,
      loading: false,
      page: 1,
      ordering: [
        {
          orderColumn: "created_at",
          orderBy: "DESC",
        },
      ],
      providers: null,
      suppliers: null,
      contractors: null,
      start_date: null,
      end_date: null,
      entered_start_date: null,
      entered_end_date: null,
      status: null,
    };
  },
  computed: {
    vgtFormattedOrdering() {
      return this.ordering.map((o) => ({
        field: o.orderColumn,
        type: o.orderBy.toLowerCase(),
      }));
    },
    passes() {
      return this.$store.state.accesses;
    },
    me() {
      return this.$store.state.me;
    },
    columns() {
      return [
        {
          label: "ID",
          field: "id",
          sortable: true,
        },
        {
          label: "Дата",
          field: "created_at",
          sortable: true,
        },
        {
          label: "Дата въезда",
          field: "entered_at",
          sortable: true,
        },
        {
          label: "Поставщик",
          field: "provider",
          sortable: true,
        },
        {
          label: "Подрядчик",
          field: "delivery",
          sortable: true,
        },
        {
          label: "Куратор",
          field: "supplier",
          sortable: true,
        },
        {
          label: "Статус",
          field: "status",
          sortable: true,
        },
        {
          field: "pass",
          sortable: false,
        },
        {
          field: "actions",
          sortable: false,
        },
      ];
    },
  },
  created() {
    let storageVariables = localStorage.getItem("passes_list." + this.me.id);
    if (storageVariables) {
      storageVariables = JSON.parse(storageVariables);
      Object.keys(storageVariables).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(this, key)) {
          this[key] = storageVariables[key];
        }
      });
    }
    this.getPasses();
  },
  methods: {
    getRoute(id) {
      return { name: "Pass", params: { id: id } };
    },
    getStatus(row) {
      let string = "status";
      switch (row.status.code) {
        case 1:
          break;
        case 6:
          string += " status--red";
          break;
        case 2:
          string += " status--orange";
          break;
        case 3:
        case 4:
        case 5:
          string += " status--green";
          break;
      }
      return string;
    },
    getPasses() {
      if (!this.loading) {
        this.loading = true;
        this.$store.state.accesses = {};
        let variables = this.clean({
          contractors: this.contractors,
          ordering: this.ordering,
          suppliers: this.suppliers,
          providers: this.providers,
          status: this.status,
          start_date: this.start_date,
          end_date: this.end_date,
          entered_start_date: this.entered_start_date,
          entered_end_date: this.entered_end_date,
          page: this.page,
          first: 11,
        });
        localStorage.setItem("passes_list." + this.me.id, JSON.stringify(variables));
        this.$store
          .dispatch("GET_PASSES", {
            context: this,
            variables: variables,
          })
          .then(() => {
            this.loading = false;
          });
      }
    },
    clean(obj) {
      for (let propName in obj) {
        if (
          Object.prototype.hasOwnProperty.call(obj, propName) &&
          (obj[propName] === null || obj[propName] === undefined)
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },
    actionsChange(event) {
      this.page = 1;
      switch (event.name) {
        case "providers":
        case "suppliers":
        case "contractors":
        case "status":
          this.changeField(event);
          break;
        case "date":
          if (event.value) {
            const format = "YYYY-MM-DD HH:mm:ss";
            this.start_date = moment(event.value.start).format(format);
            this.end_date = moment(event.value.end).format(format);
          } else {
            this.start_date = null;
            this.end_date = null;
          }
          break;
        case "date_entered":
          if (event.value) {
            const format = "YYYY-MM-DD HH:mm:ss";
            this.entered_start_date = moment(event.value.start).format(format);
            this.entered_end_date = moment(event.value.end).format(format);
          } else {
            this.entered_start_date = null;
            this.entered_end_date = null;
          }
          break;
      }
      this.getPasses();
    },
    changeField(event) {
      if (event.value.length) {
        this[event.name] = event.value.map((p) => p.id);
      } else {
        this[event.name] = null;
      }
    },
    actionsReset() {
      this.page = 1;
      this.providers = null;
      this.suppliers = null;
      this.contractors = null;
      this.start_date = null;
      this.end_date = null;
      this.entered_start_date = null;
      this.entered_end_date = null;
      this.status = null;
      this.getPasses();
    },
    changePage(e) {
      this.page = e;
      this.getPasses();
    },
    changeSort(event) {
      this.ordering = [];
      event.forEach((column) => {
        if (column.type.toUpperCase() !== "NONE") {
          this.ordering.push({
            orderColumn: column.field,
            orderBy: column.type.toUpperCase(),
          });
        }
      });
      this.getPasses();
    },
    /**
     * Обработчик для отоброжения сканера
     */
    showScanner() {
      this.isScannerQR = !this.isScannerQR;
    },
  },
  components: {
    EyeOnIcon,
    LoadingIndicator,
    Table,
    PassesListActions,
    ScannerQR,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/card.styl"
@import "~@/styles/parts/request.styl"
@import "~@/styles/mixins/svg.styl"
.contractor-btn {
  background var(--main_white)
  border 1px solid var(--main_light_gray)
  border-radius var(--button_radius)
  height 48px
  width 48px
  display inline-flex
  align-items center
  justify-content center

  .icon {
    width 18px
    height 18px
  }

  &--green {
    border-color var(--main_green_grass)
    background var(--main_green_grass)

    svg {
      svg(var(--main_white))
    }
  }

  &--red {
    border-color var(--error_red)

    svg {
      svg(var(--error_red))
    }
  }

  &--gray {
    border-color var(--main_light_gray)

    svg {
      svg(var(--main_light_gray))
    }
  }

  &__list {
    display inline-flex
    gap 12px
    align-items center
  }
}
</style>
